<template>
  <v-container fluid grey lighten-3>
    <v-container>
      <v-row>
        <v-col>
          <v-row class="title">รหัสสั่งซื้อ {{ receiverData.id }}</v-row>
          <v-row class="subtitle-2 grey--text">รายละเอียด</v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-card class="mx-auto" outlined>
          <v-card-title class="body-2"><span class="mr-2">ข้อมูลจัดส่ง</span> <Transporter :id="receiverData.transporter"></Transporter></v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>
                  <v-col md="6" sm="12" cols="12" order="2" order-md="1">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          :items="branches"
                          v-model="receiverData.ref_branch_id"
                          item-text="name"
                          item-value="id"
                          :rules="[(v) => !!v || 'กรุณาเลือก']"
                          label="สาขา"
                          dense
                          id="branches"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="receiverData.sender_info"
                          rows="3"
                          label="ข้อมูลผู้ส่ง"
                          id="sender-info"
                          dense
                          disable-lookup
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="receiverData.name"
                          :counter="250"
                          maxlength="250"
                          label="ชื่อ ลูกค้า"
                          id="cusname"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="receiverData.address_info"
                          label="ที่อยู่"
                          rows="4"
                          dense
                          id="address_info"
                          :rules="[(v) => !!v || 'กรุณากรอก']"
                          disable-lookup
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="receiverData.address_info2"
                          :items="provinceList"
                          item-value="name"
                          item-text="name"
                          item-color="blue"
                          color="purple"
                          :rules="[(v) => !!v || 'กรุณากรอก']"
                          label="ตำบล / อำเภอ / จังหวัด / รหัสไปรษณีย์"
                          id="address_info2"
                          dense
                          prepend-icon="location_on"
                          disable-lookup
                          :filter="customFilterAutocomplete"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-model="receiverData.tel1"
                          :rules="[telRules.required, telRules.counter]"
                          label="เบอร์โทร"
                          id="tel1"
                          maxlength="13"
                          dense
                          prepend-icon="phone"
                          v-mask="mask"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="receiverData.tel2"
                          :rules="[telRules.counter]"
                          label="เบอร์ สำรอง"
                          id="tel2"
                          maxlength="13"
                          dense
                          v-mask="mask"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="receiverData.remark_customer"
                          :counter="120"
                          maxlength="120"
                          label="หมายเหตุ ลูกค้า"
                          id="remark_customer"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12 mt-5 mb-5">
                        <v-text-field
                          v-model="receiverData.remark_admin"
                          :counter="120"
                          maxlength="120"
                          label="หมายเหตุ admin"
                          id="remark_admin"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col v-if="liveorder.detail">
                        <v-chip color="pink" label small dark><b>Live id# {{liveorder.id}}</b></v-chip> : 
                        {{liveorder.detail}}<br/><b>Live order</b>: {{liveorder.createdby}}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="6" sm="12" cols="12" order="1" order-md="2">
                    <v-row>
                      <v-col v-if="isAdminLevel" cols="12" md="9" offset-md="3">
                        Created by: {{ user_created }}
                      </v-col>
                      <v-col cols="12" md="90" offset-md="3" class="subtitle-2">
                        วันที่สั่งซื้อ
                        <span
                          class="red--text pl-1 font-weight-bold"
                          v-if="receiverData.createdAt"
                          >{{
                            receiverData.createdAt | moment("DD-MM-YYYY HH:mm")
                          }}</span
                        >
                        (updated
                        <span
                          class="blue--text pl-1 subtitle-2"
                          v-if="receiverData.updatedAt"
                          >{{
                            receiverData.updatedAt | moment("DD-MM-YYYY HH:mm")
                          }}</span
                        >)
                      </v-col>

                      <v-col cols="12" md="9" offset-md="3" class="subtitle-2">
                        Pickup Date/วันที่บันทึก Track:
                        <span
                          class="green--text pl-1 font-weight-bold"
                          v-if="receiverData.tracking_date"
                          >{{
                            receiverData.tracking_date | moment("DD-MM-YYYY HH:mm")
                          }}</span
                        >
                      </v-col>
                      <v-col cols="12" md="5" offset-md="3">
                        <v-text-field
                          v-model="receiverData.tracking"
                          label="เลขพัสดุ Tracking"
                          id="tracking"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="receiverData.shipping_cost"
                          label="ค่าจัดส่ง"
                          id="shipping_cost"
                          dense
                          suffix="บาท"
                          type="number"
                        ></v-text-field>
                      </v-col>

                        <v-col cols="12" md="5" offset-md="3">
                        <v-text-field
                          v-model="receiverData.cod_cost"
                          label="COD Fee"
                          id="cod_cost"
                          dense
                          suffix="บาท"
                          type="number"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="receiverData.tag"
                          label="tag"
                          id="tag"
                          :counter="3"
                          maxlength="3"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        v-if="receiverData.is_cod == paymentsMethods[1].id"
                        cols="12"
                        md="9"
                        offset-md="3"
                        align="right"
                      >
                        <v-card outlined id="stock_card">
                          <v-list-item two-line>
                            <v-list-item-avatar
                              :color="codPaymentStatus.color"
                              size="35"
                            >
                              <!-- ทำไมถึงใส่เครือ่งหมาย : ที่ color เพื่อให้ props สามัญรู้ว่าจะมีการ bind ผูก ค่าจากตัวเข้ามา ไม่งั้นจะมองเป็น string -->
                              <v-icon color="white" size="35">{{
                                codPaymentStatus.icon
                              }}</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="title">{{
                                codPaymentStatus.msg
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="receiverData.is_cod == paymentsMethods[1].id"
                        cols="12"
                        md="9"
                        offset-md="3"
                        align="right"
                      >
                        <v-switch
                          v-model="receiverData.cod_status"
                          @click="changeCodStyle"
                          color="green"
                          label="ได้รับยอด COD แล้ว"
                        ></v-switch>

                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model.trim="receiverData.cod_paid"
                              label="วันที่ได้รับข้อมูล อ้างอิงจาก FLASH LINK ข้อมูล"
                              id="cod_paid"
                              dense
                              v-mask="maskDate"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="receiverData.cod_refpaid"
                              label="รหัสอ้างอิง โอนยอด COD"
                              id="cod_refpaid"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="5" offset-md="3" class="pb-0">
                        <v-select
                          :items="paymentsMethods"
                          v-model="receiverData.is_cod"
                          item-text="name"
                          item-value="id"
                          label="ประเภทชำระเงิน"
                          prepend-inner-icon="payment"
                          :rules="[(v) => !!v || 'กรุณาเลือก']"
                          :background-color="paymentStyle.bgColor"
                          :dark="paymentStyle.theme"
                          id="paymentsMethods"
                          filled
                          item-color="blue"
                          @change="selectPaymentMethod"
                        ></v-select>
                      </v-col>

                      <v-col cols="6" md="4" class="pb-0">
                        <v-text-field
                          v-model="receiverData.amount"
                          type="number"
                          label="ยอดรวม"
                          id="amount"
                          suffix="บาท"
                          filled
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="9" offset-md="3">
                        <v-select
                          v-model="bankinfo"
                          :items="banks"
                          item-text="name"
                          item-value="id"
                          label="ธนาคาร"
                          id="banks"
                          prepend-inner-icon="mdi-bank"
                          outlined
                          :hint="
                            `${bankinfo.account_name} | เลข บช.: ${bankinfo.account_no}`
                          "
                          persistent-hint
                          return-object
                          @change="selectBank"
                        >
                          <template v-slot:item="{ item }">
                            <v-avatar size="24" class="mr-2">
                              <v-img
                                v-if="item.image"
                                :src="item.image | imageUrl"
                              ></v-img>
                            </v-avatar>
                            <span
                              >{{ item.name }} [ {{ item.account_no }} ]
                            </span>
                            <span v-if="item.active" class="green--text"
                              ><v-icon class="ml-1" small color="green"
                                >mdi-checkbox-marked-circle</v-icon
                              ></span
                            >
                            <span class="red--text" v-else
                              ><v-icon small class="ml-1" color="red"
                                >mdi-close-circle</v-icon
                              ></span
                            >
                          </template>

                          <template v-slot:message="{ message }">
                            <span class="green--text text--darken-4 caption">{{
                              message
                            }}</span>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12" md="9" offset-md="3">
                        <v-select
                          v-model="receiverData.status"
                          :items="statusSelect"
                          :rules="[(v) => !!v || 'กรุณาเลือก']"
                          label="สถานะ"
                          item-text="name"
                          item-value="id"
                          background-color="blue lighten-5"
                          filled
                          :dark="false"
                          id="status"
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="9" offset-md="3">
                        <v-combobox
                          v-model="selecttags"
                          :items="tagitems"
                          label="ป้ายติดตาม"
                          multiple
                          chips
                          small-chips
                          flat
                          :disable-lookup="true"
                        >

                        </v-combobox>
                      </v-col>

                          <v-col cols="12" md="9" offset-md="3">
                            <v-select
                              v-model="receiverData.transporter"
                              :items="transporterSelect"
                              :rules="[(v) => !!v || 'กรุณาเลือก']"
                              label="จัดส่งโดย"
                              item-text="name"
                              item-value="id"
                              background-color="yellow lighten-5"
                              filled
                              :dark="false"
                              id="transporter"
                              dense
                            ></v-select>
                          </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-3" @click="$router.go(-1)"
                    >กลับ</v-btn
                  >
                  <v-btn color="gray" class="mr-3" @click="refreshData()"
                    ><v-icon>refresh</v-icon> refresh</v-btn
                  >
                  <v-btn :disabled="!valid" color="success" type="submit"
                    ><v-icon class="mr-1">beenhere</v-icon> บันทึก</v-btn
                  >
                </v-row>
                <v-snackbar
                  v-model="snackbar.active"
                  bottom
                  color="blue darken-2"
                  :timeout="3000"
                >
                  <v-icon dark small>check_circle</v-icon>
                  <b> {{ snackbar.msg }} </b>
                  <v-btn dark text @click="snackbar.active = false">
                    ปิด
                  </v-btn>
                </v-snackbar>
                <v-snackbar
                  v-model="snackbarFaild.active"
                  bottom
                  color="error"
                  :timeout="3000"
                >
                  <v-icon dark small>cancel</v-icon>
                  <b> {{ snackbarFaild.msg }} </b>
                  <v-btn dark text @click="snackbarFaild.active = false">
                    ปิด
                  </v-btn>
                </v-snackbar>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-row>

      <v-row class="mb-5 mt-10">
        <v-row justify="end">
          <v-dialog v-model="dialogNewProduct">
             <v-card class="pt-5 pb-5">
              <v-card-text>
                <v-select
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'กรุณาเลือก']"
                  label="กรุณาเลือก หมวดหมู่"
                  @change="selectCategory"
                  :loading="isLoading"
                ></v-select>

                <v-row>
                  <v-col
                    md="1"
                    sm="6"
                    cols="6"
                    class="pa-1"
                    v-for="(product, index) in products"
                    :key="index"
                  >
                    <ProductCard
                      :item="product"
                      @selected-item="addToCart"
                      :key="index"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogNewProduct = false"
                  >ปิด</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-col lg="12" md="12" cols="12">
          <h3 class="mb-5">
            รายการสินค้า
            <v-btn color="grey" small dark @click.stop="dialogNewProduct = true"
              ><v-icon class="mr-1" size="18">add_circle</v-icon>
              แก้ไขรายการสินค้า</v-btn
            >
          </h3>
          <v-row v-if="itemList.length > 0">
            <v-col
              md="6"
              class="pa-1"
              v-for="(item, index) in itemList"
              :key="index"
            >
              <v-card v-if="item.name" outlined>
                <v-list-item>
                  <v-img
                    :src="item.image | imageUrl"
                    aspect-ratio="1"
                    max-height="90"
                    max-width="90"
                    class="ma-1"
                  ></v-img>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 mb-1 "
                      ><v-icon small color="blue darken-2">check_circle</v-icon>
                      {{ item.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      item.subname
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-card-actions>
                    <v-btn class="ml-2" icon @click="minusItem(item)" small
                      ><v-icon>remove</v-icon></v-btn
                    >
                    <v-text-field
                      label="จำนวน"
                      v-model="item.qty"
                      type="number"
                      dense
                      style="width:50px;"
                      class="centered-input"
                    ></v-text-field>
                    <v-btn class="mr-2" icon @click="plusItem(item)" small
                      ><v-icon>add</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="confirmDeleteDlg" max-width="290">
            <v-card>
              <v-card-title class="headline">Confirm Delete</v-card-title>
              <v-card-text class="body">
                ต้องการลบรายการนี้ใช่หรือไม่?
                <h3>{{ itemSelectedIndex.name }}</h3>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
                <v-btn color="error" text @click="confirmDeleteItem"
                  >Confirm</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <v-divider class="mb-5"></v-divider>

    <OrderHistory
      ref="orderhistory"
      @load-new-order="loadNewOrder"
      :orderid="billid"
    ></OrderHistory>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import api, { ORDERSTATUS, PAYMENTMETHOD, TRANSPORTER } from "@/services/api";
import _ from "lodash";
import districtsJSON from "@/assets/subdistrict-clean.json";
import Transporter from "@/components/helpers/Transporter";
import ProductCard from "@/components/billing/ProductCard";
import OrderHistory from "@/components/billing/OrderHistory";
import { userLevel } from "@/components/mixins/userLevel";

export default {
  name: "BillingEdit",
  components: {
    OrderHistory,
    ProductCard,
    Transporter
  },
  directives: {
    mask,
  },
  mixins: [userLevel],
  data() {
    return {
      liveorder: {id: '', detail: '', createdby: ''},
      selecttags: [],
      tagitems: [],
      newitem: {},
      products: [],
      isLoading: false,
      categories: [],
      banks: [],
      bankinfo: { account_name: "", account_no: "" },
      dialogNewProduct: false,
      confirmDeleteDlg: false,
      itemSelectedIndex: {}, //สำหรับลบ
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      codPaymentStatus: {
        paid: false,
        icon: "",
        date: "",
        color: "",
        msg: "",
      },
      provinceList: [],
      itemList: [],
      branches: [],
      mask: "###-###-###-#",
      maskDate: "####-##-##",
      paymentStyle: { bgColor: "", theme: false },
      telRules: {
        required: (v) => !!v || "กรุณากรอก",
        counter: (v) => {
          if (typeof v != "undefined" && v != "") {
            return (v.length >= 11 && v.length <= 13) || "กรอกให้ถูกต้อง";
          } else {
            return true;
          }
        },
      },
      paymentsMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" },
      ],
      statusSelect: [
        { id: ORDERSTATUS.WAIT, name: "รอ" },
        { id: ORDERSTATUS.PENDING, name: "รอจัดการ" },
        { id: ORDERSTATUS.PRINTED, name: "PRINTED" },
        { id: ORDERSTATUS.KAEXPORTED, name: "KA-Exported" },
        { id: ORDERSTATUS.BOOKING, name: "KA-Booking" },
        { id: ORDERSTATUS.KAPRINTED, name: "KA-PRINTED" },
        { id: ORDERSTATUS.KERRYEXPORTED, name: "KERRY-Exported" },
        { id: ORDERSTATUS.KERRYPRINTED, name: "KERRY-Printed" },
        { id: ORDERSTATUS.KERRYBOOKING, name: "KERRY-Booking" },
        { id: ORDERSTATUS.COMPLETED, name: "สำเร็จ" },
        { id: ORDERSTATUS.CANCELLED, name: "ยกเลิก" },
        { id: ORDERSTATUS.PREORDER, name: "Pre-Order" },
      ],
      transporterSelect: [
        {id: TRANSPORTER.FLASHEXPRESS, name: "Flash Express"},
        {id: TRANSPORTER.KERRYEXPRESS, name: "KERRY Express"},
      ],
      valid: true,
      receiverData: {
        id: 0,
        name: "",
        sender_info: "",
        address_info: "",
        address_info2: "",
        tel1: "",
        tel2: "",
        amount: 0,
        tag: "",
        remark_customer: "",
        remark_admin: "",
        ref_branch_id: 0,
        ref_bank_id: "",
        ref_user_id: 0,
        createdAt: "",
        status: 0,
        is_cod: 0,
        cod_status: 0,
        cod_paid: "",
        cod_refpaid: "",
        transporter: ""
      },
      old_tracking: '',
      billid: "",
      user_created: "",
    };
  },
  computed: {
    isAdminLevel() {
      return this.isShow(); // from userLevel mixins
    },
  },
  methods: {
    loadNewOrder(item) {
      this.billid = item.id;
      this.$router.replace({
        name: "BillingEdit",
        params: { id: this.billid },
      });
      this.getDataBill();
      //this.$refs.orderhistory.loadData();
    },
    addToCart(item) {
      this.newitem = { item };
    },
    async selectCategory(id) {
      this.isLoading = true;
      let result = await api.getProductByCategoryId(id);
      this.products = result.data;
      this.isLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("id", this.receiverData.id);
        formData.append("name", this.receiverData.name);
        formData.append("sender_info", this.receiverData.sender_info);
        formData.append("address_info", this.receiverData.address_info);
        formData.append("address_info2", this.receiverData.address_info2);
        formData.append("tel1", this.receiverData.tel1);
        formData.append("tel2", this.receiverData.tel2);
        formData.append("amount", this.receiverData.amount || 0);
        formData.append("tag", this.receiverData.tag);
        formData.append("remark_customer", this.receiverData.remark_customer);
        formData.append("remark_admin", this.receiverData.remark_admin);
        formData.append("ref_branch_id", this.receiverData.ref_branch_id);
        formData.append("ref_bank_id", this.receiverData.ref_bank_id || 0);
        formData.append("items", JSON.stringify(this.itemList));
        formData.append("status", this.receiverData.status);
        formData.append("tracking", this.receiverData.tracking);
        formData.append("old_tracking", this.old_tracking);
        formData.append("shipping_cost", this.receiverData.shipping_cost);
        formData.append("cod_cost", this.receiverData.cod_cost);
        formData.append("multitags", JSON.stringify(this.selecttags));

        formData.append("is_cod", this.receiverData.is_cod);
        formData.append("cod_status", this.receiverData.cod_status ? 1 : 0); // 1 โอนแล้ว 0 ยัง
        formData.append("cod_paid", this.receiverData.cod_paid); // วันที่โอน
        formData.append("cod_refpaid", this.receiverData.cod_refpaid); //
        formData.append("transporter", this.receiverData.transporter);

        let result = await api.updateOrder(formData);
        if (result.data.result == "ok") {
          let item = JSON.parse(result.data.message);
          this.snackbar = {
            active: true,
            msg: `บันทึกแก้ไขข้อมูลสำเร็จ`,
          };
          // this.reset();
        } else {
          this.snackbarFaild = {
            active: true,
            msg: `ไม่สามารถบันทึกข้อมูลได้`,
          };
        }
        // // this.$router.push("/category");
        this.getDataBill(); //reload data
        this.$refs.orderhistory.loadData();
      }
    },
    customFilterAutocomplete(item, queryText, itemText) {
      if (queryText != "") {
        let text = item.name;
        let searchTxt = _.trim(queryText);
        if (searchTxt.length > 2) {
          return text.indexOf(searchTxt) > -1;
          //return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    selectBank(item) {
      this.receiverData.ref_bank_id = item.id;
    },
    selectPaymentMethod(id) {
      if (id == 1) {
        this.paymentStyle.bgColor = "blue";
        this.paymentStyle.theme = true;
      } else if (id == 2) {
        this.paymentStyle.bgColor = "yellow";
        this.paymentStyle.theme = false;
      }
    },
    plusItem(item) {
      let i = _.findIndex(this.itemList, { id: item.id });
      if (i >= 0) {
        let qty = Number.parseInt(this.itemList[i].qty);
        this.itemList[i].qty = qty + 1;
      }
    },
    minusItem(item) {
      let i = _.findIndex(this.itemList, { id: item.id });
      if (i >= 0) {
        let qty = Number.parseInt(this.itemList[i].qty);
        if (qty > 1) {
          this.itemList[i].qty = qty - 1;
        } else {
          this.deletItem(item, i);
        }
      }
    },
    deletItem(item, index) {
      this.confirmDeleteDlg = true;
      this.itemSelectedIndex = {
        index,
        name: item.name,
      };
    },
    confirmDeleteItem() {
      this.itemList.splice(this.itemSelectedIndex.index, 1);
      this.confirmDeleteDlg = false;
    },
    changeCodStyle() {
      if (this.receiverData.cod_status) {
        this.codPaymentStatus = {
          paid: true,
          icon: "check_circle",
          color: "green",
          msg: "ได้รับยอด COD แล้ว",
        };
      } else {
        this.codPaymentStatus = {
          paid: true,
          icon: "refresh",
          color: "warning",
          msg: "COD รอดำเนินการ",
        };
      }
    },
    async getDataBill() {
      let result = await api.getOrder(this.billid);

      if (typeof result.data.order !== 'undefined') {
        if (result.data.order.tel1 != "") {
          let tel = result.data.order.tel1;
          let tel1 =
            tel.slice(0, 3) + "-" + tel.slice(3, 6) + "-" + tel.slice(6, 9);
          if (tel.length == 10) {
            tel1 += "-" + tel.slice(9, 10);
            result.data.order.tel1 = tel1;
          }
        }

        if (result.data.order.tel2 != "") {
          let tel = result.data.order.tel2;
          let tel2 =
            tel.slice(0, 3) + "-" + tel.slice(3, 6) + "-" + tel.slice(6, 9);
          if (tel.length == 10) {
            tel2 += "-" + tel.slice(9, 10);
            result.data.order.tel2 = tel2;
          }
        }
        //console.log(result.data.items);

        this.itemList = result.data.items;
        this.selectPaymentMethod(result.data.order.is_cod);
        if (result.data.order.cod_status == 1) {
          this.codPaymentStatus = {
            paid: true,
            icon: "check_circle",
            color: "green",
            msg: "ได้รับยอด COD แล้ว",
          };
        } else {
          this.codPaymentStatus = {
            paid: true,
            icon: "refresh",
            color: "warning",
            msg: "COD รอดำเนินการ",
          };
        }

        result.data.order.cod_paid = result.data.order.cod_paid
          ? result.data.order.cod_paid.toString().substring(0, 10)
          : "";

        let bankData = _.find(this.banks, {
          id: result.data.order.ref_bank_id,
        });
        if (bankData) {
          this.bankinfo = {
            account_name: bankData.account_name,
            account_no: bankData.account_no,
            id: result.data.order.ref_bank_id,
          };
        }

        // get tags
        let result_tags = await api.getTagAll();
        if (result_tags.data.result == "ok") {
          this.tagitems = JSON.parse(result_tags.data.message);
          let result_selected_tags = await api.getTagByOrderId(this.billid);
          if (result_selected_tags.data.result == "ok") {
            this.selecttags = JSON.parse(result_selected_tags.data.message);
          }
        }
        this.receiverData = { ...result.data.order };
        this.old_tracking = this.receiverData.tracking;
      } else {
         // token is expired
        this.$router.push('/logout');
      }
    },
    async refreshData() {
      await this.getDataBill();
    },
    async getUserCreated() {
      let user = await api.getUserById(this.receiverData.ref_user_id);
      this.user_created = user.data.username;
    },
  },
  async mounted() {
    this.billid = this.$route.params.id;
    let result = await api.getBranchActive();
    this.branches = result.data;
    this.provinceList = districtsJSON;

    let bankResult = await api.getBankAll();
    this.banks = bankResult.data;

    await this.getDataBill();

    let cat = await api.getCategoryActive();
    this.categories = cat.data;

    this.getUserCreated();

    let rliveorder = await api.getLiveOrderByOrderId(this.billid)
    if (rliveorder.data.result == 'ok') {
      let rlo = JSON.parse(rliveorder.data.message);

      let rusercreated = await api.getUserById(rlo.ref_user_id);
      this.liveorder = {id: rlo.id, detail: rlo.detail, createdby: rusercreated.data.username}
    } else {
      this.liveorder = {};
    }

    console.log("mounted in billing edit");
  },
  watch: {
    newitem: {
      //from props
      handler(newVal, oldVal) {
        let item = newVal.item;
        let i = _.findIndex(this.itemList, { id: item.id });

        if (i < 0) {
          //not found
          this.itemList.push({
            id: item.id,
            name: item.name,
            image: item.image,
            subname: item.subname,
            qty: 1,
            cost: item.cost,
          });
        }
        // } else {
        //   this.itemList[i].qty += 1;
        // }
      },
      deep: true,
    },
  },
  beforeDestroy: function(){
    delete this.selecttags;
    delete this.tagitems;
    delete this.newitem;
    delete this.products;
    delete this.categories;
    delete this.banks;
    delete this.provinceList;
    delete this.itemList;
    delete this.branches;
    delete this.receiverData;
  }
};
</script>

<style></style>
